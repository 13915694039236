import React from 'react'

import { Grid, Row, Col, Content } from './styled'
import { rgba } from './helpers'

const Nav = () => {
  return (
    <Grid>
      <Row height={3}>
        <Col size={1} bg={rgba()}>
          <Content center>LOGO</Content>
        </Col>
        <Col size={1} bg={rgba()}>
          <Content center>NAV</Content>
        </Col>
      </Row>
    </Grid>
  )
}

export default Nav
