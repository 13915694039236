import React from 'react'

import { Grid, Row, Col, Content } from './styled'
import { rgba } from './helpers'

const About = () => {
  return (
    <Grid>
      <Row height={20}>
        <Col size={1} bg={rgba()}>
          <Content center x='flex-end' padding='p2'>
            HEADSHOT
          </Content>
        </Col>
        <Col size={1} bg={rgba()}>
          <Content center x='flex-start' padding='p2'>
            <div>
              <h1>About Me</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
                debitis officia hic reprehenderit dolore error accusamus eum,
                porro repellendus magnam dignissimos nobis quaerat voluptatum
                quam similique quisquam ut quasi explicabo.
              </p>
            </div>
          </Content>
        </Col>
      </Row>
    </Grid>
  )
}

export default About
