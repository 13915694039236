import React from 'react'

import Nav from './components/nav'
import Banner from './components/banner'
import About from './components/about'
import Clients from './components/clients'
import Planner from './components/planner'
import Twitter from './components/twitter'
import Instagram from './components/instagram'
import Reviews from './components/reviews'
import Contact from './components/contact'

function App() {
  return (
    <div className='App'>
      <Nav />
      <Banner />
      <About />
      <Clients />
      <Planner />
      <Twitter />
      <Instagram />
      <Reviews />
      <Contact />
    </div>
  )
}

export default App
