import React from 'react'

import { Grid, Row, Col, Content } from './styled'
import { rgba } from './helpers'

const Twitter = () => {
  return (
    <Grid>
      <Row height={20}>
        <Col size={1} bg={rgba()}>
          <Content center>TWITTER</Content>
        </Col>
      </Row>
    </Grid>
  )
}

export default Twitter
